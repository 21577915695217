import React, { FC } from "react";
import { Box, CircularProgress, Grid, ScrollableTable } from "@toolkit/ui";
import { TemplateActivityEventItem } from "@health/queries/types";
import { useGuidedCareProgramTemplateActivityGetQuery } from "pages/Programs/gql";
import { useGuidedCareProgramTemplateActivityItemListColumns } from "./useGuidedCareProgramTemplateActivityItemListColumns";

type GuidedCareProgramTemplateActivityItemListProps = {
  templateActivityId: string;
};

export const GuidedCareProgramTemplateActivityItemList: FC<GuidedCareProgramTemplateActivityItemListProps> = props => {
  const { templateActivityId } = props;

  const columns = useGuidedCareProgramTemplateActivityItemListColumns();

  const { data, loading } = useGuidedCareProgramTemplateActivityGetQuery({
    variables: {
      templateActivityId,
    },
    skip: !templateActivityId,
  });

  const activityItems = data?.templateActivity?.templateActivityEventItems as TemplateActivityEventItem[];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress color={"inherit"} size={20} />
          </Box>
        ) : (
          <ScrollableTable data={activityItems} columns={columns} />
        )}
      </Grid>
    </Grid>
  );
};
