import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, FormCard, Grid, PageWrapper } from "@toolkit/ui";
import { HealthProgramTemplate } from "@health/queries/types";
import { convertTemplateToFormValues } from "pages/Templates/others";
import { GuidedCareTemplateUpsertFormButtonTitle, IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplatePublish } from "../../components/TemplatePublish/GuidedCareTemplatePublish";
import { GuidedCareTemplateActivitiesForm } from "../TemplateActivities/GuidedCareTemplateActivitiesForm";
import { GuidedCareTemplateInformationForm } from "../TemplateInformation/GuidedCareTemplateInformationForm";
import { GuidedCareTemplateMembersForm } from "../TemplateMembers/GuidedCareTemplateMembersForm";
import { GuidedCareTemplateVerificationForm } from "../TemplateVerification/GuidedCareTemplateVerificationForm";
import {
  GuidedCareTemplateUpsertFormSchema,
  IGuidedCareTemplateUpsertFormValues,
  guidedCareTemplateUpsertFormDefaultValues,
} from "./GuidedCareTemplateUpsertFormSchema";
import { useGuidedCareTemplateUpsertFormStyle } from "./useGuidedCareTemplateUpsertFormStyle";

type GuidedCareTemplateUpsertFormProps = {
  submitButtonLabel: GuidedCareTemplateUpsertFormButtonTitle;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
  template?: HealthProgramTemplate;
  onChange: (values: IGuidedCareTemplateUpsertFormEvent) => void;
};

export const GuidedCareTemplateUpsertForm: FC<GuidedCareTemplateUpsertFormProps> = props => {
  const { submitButtonLabel, isLoading, isButtonDisabled, template, onChange } = props;

  const { t } = useTranslation("gcadmin");
  const navigate = useNavigate();

  const { classes } = useGuidedCareTemplateUpsertFormStyle();

  const form = useCustomForm<IGuidedCareTemplateUpsertFormValues>({
    defaultValues: guidedCareTemplateUpsertFormDefaultValues,
    schema: GuidedCareTemplateUpsertFormSchema,
  });

  const { watch, setValues, handleSubmit } = form;

  const isVerified = watch("isVerified");

  const onSubmit = (values: IGuidedCareTemplateUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (template) {
      const _template = convertTemplateToFormValues(template);
      setValues(_template);
    }
  }, [setValues, template]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box className={classes.formActionsWrapper}>
              <FormActions
                hasCancel
                hasFormButton
                isLoading={isLoading}
                formButtonTitle={submitButtonLabel}
                onNavigation={handleCancel}
                newButtonDisabled={isButtonDisabled}
              />

              {template?.isDraft && template?.code && <GuidedCareTemplatePublish templateCode={template.code} />}
            </Box>
          }
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormCard title={t("Template Information")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateInformationForm isUpdateMode={!!template} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard title={t("Template Verification")} loading={isLoading} doYouHaveData>
                <GuidedCareTemplateVerificationForm isVerified={isVerified} />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateMembersForm />
              </FormCard>
            </Grid>

            <Grid item xs={12}>
              <FormCard loading={isLoading} doYouHaveData>
                <GuidedCareTemplateActivitiesForm />
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
