import { z } from "zod";
import { zodSchema } from "@health/domains";

export const GuidedCareTemplateActivityItemUpdateFormSchema = z.object({
  offsetInDays: z.coerce.number().min(0),
  beforeActivity: zodSchema.medicalMessageAutocompleteOptional,
  afterActivity: zodSchema.medicalMessageAutocompleteOptional,
  beforeActivityOffset: z.coerce
    .number()
    .min(0)
    .optional()
    .nullable()
    .transform(val => val || null),
  afterActivityOffset: z.coerce
    .number()
    .min(0)
    .optional()
    .nullable()
    .transform(val => val || null),
});

export type IGuidedCareTemplateActivityItemUpdateFormValues = z.infer<typeof GuidedCareTemplateActivityItemUpdateFormSchema>;

export const guidedCareTemplateActivityItemUpdateFormDefaultValues: Partial<IGuidedCareTemplateActivityItemUpdateFormValues> = {
  offsetInDays: undefined,
  beforeActivity: null,
  afterActivity: null,
  beforeActivityOffset: null,
  afterActivityOffset: null,
};
