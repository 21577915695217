import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareTemplateActivityUpsertFormEvent } from "pages/Templates/types";
import {
  GuidedCareTemplateActivityUpsertForm,
  GuidedCareTemplateActivityUpsertFormRef,
} from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertForm";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

type GuidedCareTemplateActivityUpsertModalProps = BaseDialogProps & {
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[];
  activity?: IGuidedCareTemplateActivityUpsertFormValues;
  onChange: (event: IGuidedCareTemplateActivityUpsertFormEvent) => void;
};

export const GuidedCareTemplateActivityUpsertModal: FC<GuidedCareTemplateActivityUpsertModalProps> = props => {
  const { teamMembers, activity, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");
  const activityUpsertFormRef = useRef<GuidedCareTemplateActivityUpsertFormRef>(null);

  const onButtonClick = () => {
    activityUpsertFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={activity ? t("Update Activity") : t("Add Activity")}
      confirmButton={{ label: activity ? t("Update") : t("Add"), onClick: onButtonClick }}
      cancelButton={activity ? { label: t("Cancel") } : undefined}
    >
      <GuidedCareTemplateActivityUpsertForm teamMembers={teamMembers} activity={activity} onChange={onChange} ref={activityUpsertFormRef} />
    </BaseDialog>
  );
};
