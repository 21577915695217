import { guidedCareActivityTypeMapper } from "@health/enum-options";
import { TemplateActivity } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, paths } from "@toolkit/ui";
import { FC } from "react";
import { useGuidedCareTemplateActivitiesItemsTimelineItemIconStyle } from "./useGuidedCareTemplateActivitiesItemsTimelineItemIconStyle";

type GuidedCareTemplateActivitiesItemsTimelineItemIconProps = {
  selectItem: TemplateActivity;
  item: TemplateActivity;
  onSelectItem: (item: TemplateActivity) => void;
};

export const GuidedCareTemplateActivitiesItemsTimelineItemIcon: FC<GuidedCareTemplateActivitiesItemsTimelineItemIconProps> = props => {
  const { item, selectItem, onSelectItem } = props;

  const { t } = useTranslation("gcadmin");

  const { classes, theme } = useGuidedCareTemplateActivitiesItemsTimelineItemIconStyle();

  const { icon, viewBox, color } = guidedCareActivityTypeMapper(item?.templateActivityType!, t);

  const isSelected = selectItem?.id === item?.id || selectItem?.templateActivityType === item?.templateActivityType;

  const handleSelectItem = () => {
    onSelectItem(item);
  };

  return (
    <Box
      className={classes.icon}
      sx={{
        background: isSelected ? theme.palette.primary.main : theme.palette.common.white,
        border: "1.5px solid",
        borderColor: theme.palette.primary.main,
        "&:hover": {
          border: "1px solid",
          borderColor: color,
          background: color,
          "& svg": {
            fill: theme.palette.common.white,
          },
          "#id": {
            "& svg": {
              fill: theme.palette.primary.main,
            },
          },
        },
      }}
      onClick={handleSelectItem}
    >
      <Box
        sx={{
          "& svg": {
            fill: isSelected ? theme.palette.common.white : theme.palette.primary.main,
          },
        }}
      >
        <CustomIcon icon={icon as keyof typeof paths} color={theme.palette.primary.main} viewBox={viewBox} />
      </Box>
    </Box>
  );
};
