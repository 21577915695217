import { i18n } from "@toolkit/i18n";
import { labTemplatesPaths } from "./labTemplatesPaths";
import { adminTranslationNamespace } from "utils/translation";

export const labTemplatesTitleBreadcrumb = () => {
  return i18n.t("Lab Templates", adminTranslationNamespace);
};

export const labTemplateListBreadcrumb = () => {
  return {
    id: "lab-template-list",
    name: i18n.t("Lab Templates", adminTranslationNamespace),
    route: labTemplatesPaths.listPath.fullPath,
  };
};

export const labTemplateNewBreadcrumb = () => {
  return {
    id: "lab-template-new",
    name: i18n.t("New Template", adminTranslationNamespace),
  };
};

export const labTemplateFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "lab-template-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};

export const labTemplateParametersBreadcrumb = () => {
  return {
    id: "lab-template-parameters",
    name: i18n.t("Parameters", adminTranslationNamespace),
  };
};

export const labTemplateCloneBreadcrumb = () => {
  return {
    id: "lab-template-clone",
    name: i18n.t("Clone Template", adminTranslationNamespace),
  };
};
