/* eslint-disable max-lines */
import { find, isEqual } from "lodash";
import { toSentenceCase } from "@toolkit/core";
import { createAutocompleteOption, mapToAutocompleteOptions } from "@toolkit/ui";
import {
  appointmentFrequencyTypeOptionsMap,
  codeSystemCodeOptionsMap,
  guidedCareActivityTypeOptionsMap,
  guidedCareActivityTypeToTeamMemberPositionMap,
  guidedCareTeamMemberPositionOptionsMap,
  guidedCareTeamMemberWithActivityOptions,
  guidedCareTypeOptionsMap,
  isGuidedCareActivityTypeMedicalMessage,
  isGuidedCareTeamMemberNonManagement,
  medicalMessageCategoryOptionsMap,
} from "@health/enum-options";
import {
  GuidedCareActivityType,
  HealthProgramTemplate,
  HealthProgramTemplateInput,
  InputMaybe,
  Maybe,
  ServiceDetails,
  TemplateActivity,
  TemplateActivityEventItemInput,
  TemplateActivityInput,
  TemplateTeamMember,
} from "@health/queries/types";
import { GuidedCareTemplateGetQuery } from "pages/Templates/gql";
import { IGuidedCareTemplateActivityItems } from "pages/Templates/types";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";
import { IGuidedCareTemplateActivityServiceUpsertFormValues } from "../forms/TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertFormSchema";
import { IGuidedCareTemplateActivityUpsertFormValues } from "../forms/TemplateActivityUpsert/GuidedCareTemplateActivityUpsertFormSchema";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";
import { IGuidedCareTemplateUpsertFormValues } from "../forms/TemplateUpsert/GuidedCareTemplateUpsertFormSchema";

export const getTeamMemberLabel = (teamMember: IGuidedCareTemplateMemberUpsertFormValues) => {
  const teamMemberSpecialization =
    isGuidedCareTeamMemberNonManagement(teamMember?.position?.value!) && teamMember?.specialization?.label
      ? ` - ${toSentenceCase(teamMember?.specialization?.label)}`
      : "";

  return `${teamMember?.position?.label}${teamMemberSpecialization}`;
};

export const convertActivityServicesToFormValues = (
  activityServices?: Maybe<Array<Maybe<ServiceDetails>>>
): IGuidedCareTemplateActivityServiceUpsertFormValues[] => {
  return (
    activityServices?.map(service => ({
      serviceId: service?.id!,
      standard: codeSystemCodeOptionsMap[service?.standard!],
      service:
        service?.serviceCode && service?.serviceName
          ? createAutocompleteOption({ code: service?.serviceCode, display: service?.serviceName }, "code", "display")
          : null,
    })) || []
  );
};

export const convertActivityItemsFormValuesToBackEndValues = (
  activityItems: IGuidedCareTemplateActivityItemUpdateFormValues[]
): TemplateActivityEventItemInput[] => {
  return activityItems.map(item => {
    return {
      offsetInDays: item.offsetInDays,
      beforeCallMessageId: item.beforeActivity?.value?.id,
      beforeCallMessageOffsetDays: item.beforeActivityOffset,
      afterCallMessageId: item.afterActivity?.value?.id,
      afterCallMessageOffsetDays: item.afterActivityOffset,
    };
  });
};

export const filterTeamMembersByActivityType = (
  teamMembers: IGuidedCareTemplateMemberUpsertFormValues[],
  activityType: GuidedCareActivityType
) => {
  const activityTypePositions = guidedCareActivityTypeToTeamMemberPositionMap[activityType] || [];

  const filteredTeamMembers =
    teamMembers?.filter(item => {
      const teamMember = guidedCareTeamMemberPositionOptionsMap[item?.position?.value];
      return (
        teamMember &&
        guidedCareTeamMemberWithActivityOptions.some(teamMemberActivity => {
          return teamMemberActivity?.value === teamMember?.value && activityTypePositions.includes(teamMember?.value);
        })
      );
    }) || [];

  return mapToAutocompleteOptions(filteredTeamMembers, "uniqueId", getTeamMemberLabel);
};

export const getMedicalMessageCategory = (medicalMessageLabel: string) => {
  const medicalMessageLabelSplit = medicalMessageLabel?.split("-");
  const medicalMessageCategory = medicalMessageLabelSplit?.length > 1 ? medicalMessageLabelSplit[1]?.toLowerCase()?.trim() : "";

  const medicalMessageCategoryObject = find(medicalMessageCategoryOptionsMap, option =>
    option?.label?.toLowerCase()?.includes(medicalMessageCategory)
  );

  return medicalMessageCategoryObject?.key || undefined;
};

export const shouldTemplateActivityMessagesAndOffsetsShow = (templateActivityType: GuidedCareActivityType) => {
  return templateActivityType !== GuidedCareActivityType.MedicalForm && !isGuidedCareActivityTypeMedicalMessage(templateActivityType);
};

export const convertTemplateTeamMemberToFormValues = (
  templateTeamMember?: Maybe<TemplateTeamMember>
): IGuidedCareTemplateMemberUpsertFormValues => {
  return {
    teamMemberId: templateTeamMember?.id!,
    uniqueId: templateTeamMember?.id!,
    position: guidedCareTeamMemberPositionOptionsMap[templateTeamMember?.position!],
    specialization: templateTeamMember?.specialization
      ? createAutocompleteOption(
          { code: templateTeamMember?.specialization!, display: templateTeamMember?.specializationDisplay! },
          "code",
          "display"
        )
      : undefined,
    isLicencedHealthProfessional: !!templateTeamMember?.isLicencedHealthProfessional,
    isBackupPersonMandatory: !!templateTeamMember?.isBackupPersonMandatory,
    isKeyTeamMember: !!templateTeamMember?.isKeyTeamMember,
  };
};

export const convertTemplateTeamMembersToFormValues = (
  templateTeamMembers?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateMemberUpsertFormValues[] => {
  return templateTeamMembers?.map(item => convertTemplateTeamMemberToFormValues(item)) || [];
};

export const convertTemplateActivitiesToFormValues = (
  templateActivities?: Maybe<Maybe<TemplateActivity>[]>,
  templateTeamMembers?: Maybe<Maybe<TemplateTeamMember>[]>
): IGuidedCareTemplateActivityUpsertFormValues[] => {
  return (
    templateActivities?.map(item => {
      const teamMember = templateTeamMembers?.find(templateTeamMember => templateTeamMember?.id! === item?.templateTeamMember?.id!);

      return {
        activityId: item?.id!,
        activityServices: convertActivityServicesToFormValues(item?.serviceOfHealthProgramTemplateDetails!),
        teamMember: createAutocompleteOption(convertTemplateTeamMemberToFormValues(teamMember), "uniqueId", getTeamMemberLabel),
        templateActivityType: guidedCareActivityTypeOptionsMap[item?.templateActivityType!],
        frequencyType: appointmentFrequencyTypeOptionsMap[item?.frequencyType!],
        medicalForm: item?.medicalForm
          ? createAutocompleteOption({ id: item?.medicalForm?.id!, name: item?.medicalForm?.name! }, "id", "name")
          : undefined,
        medicalMessage: item?.medicalMessage
          ? createAutocompleteOption({ id: item?.medicalMessage?.id!, name: item?.medicalMessage?.name! }, "id", "name")
          : undefined,
        numberOfOccurrences: item?.numberOfOccurrences!,
        beforeActivity: item?.beforeCallMessage!
          ? createAutocompleteOption({ id: item?.beforeCallMessage?.id!, name: item?.beforeCallMessage?.name! }, "id", "name")
          : null,
        beforeActivityOffset: item?.beforeCallMessageOffsetDays!,
        afterActivity: item?.afterCallMessage!
          ? createAutocompleteOption({ id: item?.afterCallMessage?.id!, name: item?.afterCallMessage?.name! }, "id", "name")
          : null,
        afterActivityOffset: item?.afterCallMessageOffsetDays!,
      };
    }) || []
  );
};

export const convertTemplateActivityItemsToFormValues = (
  templateActivityItems?: IGuidedCareTemplateActivityItems
): IGuidedCareTemplateActivityItemUpdateFormValues[] => {
  return (
    templateActivityItems?.map(item => ({
      offsetInDays: item?.offsetInDays!,
      beforeActivity: item?.beforeCallMessage!
        ? createAutocompleteOption({ id: item?.beforeCallMessage?.id!, name: item?.beforeCallMessage?.name! }, "id", "name")
        : null,
      beforeActivityOffset: item?.beforeCallMessageOffsetDays! || null,
      afterActivity: item?.afterCallMessage!
        ? createAutocompleteOption({ id: item?.afterCallMessage?.id!, name: item?.afterCallMessage?.name! }, "id", "name")
        : null,
      afterActivityOffset: item?.afterCallMessageOffsetDays! || null,
    })) || []
  );
};

export const convertTemplateToFormValues = (template: HealthProgramTemplate): IGuidedCareTemplateUpsertFormValues => {
  return {
    name: template?.name!,
    guidedCareType: guidedCareTypeOptionsMap[template?.guidedCareType!],
    isRenewable: !!template?.isRenewable,
    templateDuration: template?.templateDuration!,
    isVerified: !!template?.isVerified,
    standardCode: template?.standardCode! || "",
    standardName: template?.standardName! || "",
    standardURL: template?.standardURL! || "",
    healthCondition: template?.healthCondition
      ? createAutocompleteOption(
          { id: template?.healthCondition?.id!, code: template?.healthCondition?.code!, display: template?.healthCondition?.display! },
          "code",
          "display"
        )
      : undefined,
    riskFactorTemplate: template?.riskFactorTemplate
      ? createAutocompleteOption(
          {
            id: template?.riskFactorTemplate?.id!,
            code: template?.riskFactorTemplate?.code!,
            display: template?.riskFactorTemplate?.display!,
          },
          "code",
          "display"
        )
      : undefined,
    teamMembers: convertTemplateTeamMembersToFormValues(template?.templateTeamMembers!),
    activities: convertTemplateActivitiesToFormValues(template?.templateActivities!, template?.templateTeamMembers!),
  };
};

export const convertTemplateFormValuesToBackEndValues = (values: IGuidedCareTemplateUpsertFormValues): HealthProgramTemplateInput => {
  return {
    name: values.name,
    guidedCareType: values.guidedCareType?.value,
    isRenewable: values.isRenewable,
    isVerified: values.isVerified,
    standardCode: values.standardCode,
    standardName: values.standardName,
    standardURL: values.standardURL,
    templateDuration: values.templateDuration,
    healthConditionId: values.healthCondition?.value?.id || undefined,
    riskFactorTemplateId: values.riskFactorTemplate?.value?.id || undefined,
    templateTeamMembers: values.teamMembers.map(item => {
      const { teamMemberId, specialization, ...rest } = item;
      return {
        ...rest,
        id: teamMemberId!,
        uniqueId: item.uniqueId!,
        position: item.position?.value,
        specialization: specialization?.value?.code,
      };
    }),
    templateActivities: values.activities.map(item => {
      return {
        id: item?.activityId,
        numberOfOccurrences: item?.numberOfOccurrences,
        templateTeamMember: {
          uniqueId: item?.teamMember?.value?.uniqueId!,
        },
        templateActivityType: item?.templateActivityType.value,
        frequencyType: item?.frequencyType?.value,
        medicalFormId: item?.medicalForm?.value?.id,
        medicalMessageId: item?.medicalMessage?.value?.id,
        beforeCallMessageId: item?.beforeActivity?.value?.id,
        beforeCallMessageOffsetDays: item?.beforeActivityOffset,
        afterCallMessageId: item?.afterActivity?.value?.id,
        afterCallMessageOffsetDays: item?.afterActivityOffset,
        serviceOfHealthProgramTemplateDetails: item?.activityServices?.map(activityService => ({
          id: activityService?.serviceId,
          serviceCode: activityService.service?.value.code!,
          serviceName: activityService.service?.value.display!,
          standard: activityService.standard?.value!,
        })),
      };
    }),
  };
};

export const compareTemplateActivities = (
  oldTemplate: GuidedCareTemplateGetQuery["healthProgramTemplate"],
  newTemplate: HealthProgramTemplateInput
): InputMaybe<InputMaybe<TemplateActivityInput>[]> => {
  const isTemplateDurationModified = oldTemplate?.templateDuration !== newTemplate?.templateDuration;

  return (
    newTemplate?.templateActivities?.map(newItem => {
      const existingItem = oldTemplate?.templateActivities?.find(oldItem => oldItem?.id === newItem?.id);
      const isNewItem = !existingItem;
      const isExistingItemModified =
        existingItem &&
        (!isEqual(existingItem.numberOfOccurrences, newItem?.numberOfOccurrences) ||
          !isEqual(existingItem.frequencyType, newItem?.frequencyType));

      return {
        ...newItem,
        isModified: isNewItem || isExistingItemModified || isTemplateDurationModified,
        frequencyType: newItem?.frequencyType!,
        numberOfOccurrences: newItem?.numberOfOccurrences!,
      } as TemplateActivityInput;
    }) || []
  );
};
