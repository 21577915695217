var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { NetworkStatus } from "@apollo/client";
import { useCallback } from "react";
import { strapiClient } from "../constants";
export const useStrapiPaginatedData = (props) => {
    var _a, _b;
    const { dataAccessor, useQuery, skip } = props;
    const _c = useQuery({
        client: strapiClient,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        skip,
    }), { data: response, loading, networkStatus, fetchMore, refetch } = _c, queryResult = __rest(_c, ["data", "loading", "networkStatus", "fetchMore", "refetch"]);
    const responseDataAccessor = response === null || response === void 0 ? void 0 : response[dataAccessor];
    const data = responseDataAccessor === null || responseDataAccessor === void 0 ? void 0 : responseDataAccessor.data;
    const pagination = (_b = (_a = responseDataAccessor === null || responseDataAccessor === void 0 ? void 0 : responseDataAccessor.meta) === null || _a === void 0 ? void 0 : _a.pagination) !== null && _b !== void 0 ? _b : { page: 1, pageCount: 1, total: 0, pageSize: 10 };
    const hasMoreData = (pagination === null || pagination === void 0 ? void 0 : pagination.pageCount) > pagination.page;
    const isLoading = loading || networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.refetch;
    const fetchMoreData = useCallback(() => {
        if (hasMoreData && !isLoading) {
            fetchMore({
                variables: {
                    pagination: {
                        page: pagination.page + 1,
                        pageSize: pagination.pageSize,
                    },
                },
                updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                    var _a, _b, _c;
                    if (!fetchMoreResult || !((_a = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult[dataAccessor]) === null || _a === void 0 ? void 0 : _a.data)) {
                        return previousQueryResult;
                    }
                    return Object.assign(Object.assign({}, fetchMoreResult), { [dataAccessor]: Object.assign(Object.assign({}, fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult[dataAccessor]), { data: [...(((_b = previousQueryResult === null || previousQueryResult === void 0 ? void 0 : previousQueryResult[dataAccessor]) === null || _b === void 0 ? void 0 : _b.data) || []), ...(((_c = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult[dataAccessor]) === null || _c === void 0 ? void 0 : _c.data) || [])] }) });
                },
            });
        }
    }, [fetchMore, hasMoreData, loading, pagination.page, pagination.pageSize]);
    const handleRefetch = useCallback(() => refetch(), [refetch]);
    return Object.assign({ data,
        isLoading,
        hasMoreData,
        fetchMoreData, refetch: handleRefetch }, queryResult);
};
