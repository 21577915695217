import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";
export const CheckListIcon: FC<SvgIconProps> = props => {
  const { width, height, viewBox } = props;
  const { palette } = useTheme();

  return (
    <SvgIcon
      id='Group_8901'
      data-name='Group 8901'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width ? width : "69.18"}
      height={height ? height : "89.383"}
      viewBox={viewBox ? viewBox : "0 0 69.18 89.383"}
      {...props}
    >
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_4046' data-name='Rectangle 4046' width='69.18' height='89.383' fill={palette.common.white} />
        </clipPath>
      </defs>
      <g id='Group_6546' data-name='Group 6546' clipPath='url(#clip-path)'>
        <path
          id='Path_4404'
          data-name='Path 4404'
          d='M6.055,96.606c-.1-.138-.265-.1-.4-.131A6.976,6.976,0,0,1,.02,89.506q0-31.553,0-63.106a23.012,23.012,0,0,1,.094-3.616,6.987,6.987,0,0,1,6.668-5.778c2.968-.026,5.935,0,8.9-.014.334,0,.416.1.411.421-.018,1.091-.024,2.182,0,3.273.009.387-.083.515-.5.513-2.807-.02-5.615-.011-8.423-.009a2.791,2.791,0,0,0-2.971,2.98q0,32.622,0,65.245a2.8,2.8,0,0,0,3,3q27.409,0,54.816,0a2.794,2.794,0,0,0,2.961-2.951q0-32.644,0-65.288a2.8,2.8,0,0,0-3.01-2.987c-2.808,0-5.616-.007-8.423.008-.367,0-.465-.1-.458-.463.022-1.1.018-2.211,0-3.317,0-.313.067-.427.406-.426,2.953.014,5.906-.013,8.86.014a6.956,6.956,0,0,1,6.813,7.036q.014,8.554,0,17.108,0,24.133,0,48.268a7,7,0,0,1-4.419,6.7c-.553.221-1.142.3-1.7.487Z'
          transform='translate(0 -7.223)'
          fill={palette.common.white}
        />
        <path
          id='Path_4405'
          data-name='Path 4405'
          d='M26.01,61.1a2.071,2.071,0,0,1,1.305-1.9,2.022,2.022,0,0,1,2.312.53c1.169,1.3,2.329,2.6,3.464,3.924.285.332.419.278.675-.012q3.327-3.755,6.676-7.49a2.365,2.365,0,0,1,1.133-.777,2.08,2.08,0,0,1,2.321.878,2.051,2.051,0,0,1-.2,2.508c-.879,1.008-1.778,2-2.67,3q-2.981,3.333-5.963,6.664a2.074,2.074,0,0,1-3.321-.008q-2.547-2.872-5.09-5.748A2.2,2.2,0,0,1,26.01,61.1'
          transform='translate(-11.057 -23.508)'
          fill={palette.common.white}
        />
        <path
          id='Path_4406'
          data-name='Path 4406'
          d='M64.279,7.113c0-.375-.084-.493-.478-.489-2.487.019-4.974.006-7.461.015-.309,0-.451-.041-.47-.413a7.213,7.213,0,0,0-1.613-4.2A4.728,4.728,0,0,0,51.4.233C50.978.15,50.554.078,50.129,0H46.812c-.094.129-.239.083-.362.1a5.082,5.082,0,0,0-3.561,1.885,7.29,7.29,0,0,0-1.645,4.237c-.019.36-.143.422-.462.421-2.5-.011-5,0-7.505-.013-.343,0-.439.094-.438.437q.018,4.8,0,9.6c0,.374.117.452.469.452q15.251-.014,30.5,0c.4,0,.474-.126.473-.493q-.021-4.756,0-9.512M48.6,9.776A3.141,3.141,0,1,1,51.7,6.6a3.149,3.149,0,0,1-3.1,3.172'
          transform='translate(-13.959)'
          fill={palette.common.white}
        />
        <path
          id='Path_4407'
          data-name='Path 4407'
          d='M82.48,56.392c0-.423-.13-.539-.547-.536-2.72.017-5.44.009-8.161.009v0H65.612c-.578,0-.581,0-.581.566q0,8.182-.008,16.364c0,.423.122.539.54.538q8.2-.02,16.408,0c.423,0,.505-.152.5-.531q-.012-8.2,0-16.408M79.7,70.043c0,.383-.106.5-.492.493-1.817-.019-3.635-.009-5.453-.009s-3.606-.012-5.409.009c-.408,0-.555-.1-.553-.53.016-3.591.009-7.183.011-10.774,0-.57,0-.573.58-.573,3.606,0,7.212,0,10.818-.009.375,0,.5.1.5.488q-.022,5.453,0,10.905'
          transform='translate(-27.642 -23.745)'
          fill={palette.common.white}
        />
        <path
          id='Path_4408'
          data-name='Path 4408'
          d='M42.4,96.475c0-.425-.132-.538-.547-.535-2.72.017-5.441.009-8.161.009v0H25.526c-.577,0-.58,0-.58.567q0,8.182-.008,16.364c0,.424.124.538.54.536q8.183-.02,16.365,0c.409,0,.552-.1.551-.53q-.019-8.2,0-16.408m-2.785,8.213c0,1.817-.008,3.635.007,5.452,0,.352-.088.479-.46.478q-5.475-.018-10.949,0c-.389,0-.494-.124-.493-.5q.017-5.43,0-10.861c0-.389.105-.525.515-.524q5.453.022,10.906,0c.4,0,.486.132.482.5-.017,1.817-.007,3.634-.007,5.452'
          transform='translate(-10.601 -40.785)'
          fill={palette.common.white}
        />
        <path
          id='Path_4409'
          data-name='Path 4409'
          d='M82.479,96.517c0-.413-.076-.582-.545-.581q-8.205.024-16.41,0c-.371,0-.5.089-.5.485q.019,8.226,0,16.452c0,.42.116.539.538.538q8.161-.021,16.322-.01c.584,0,.586,0,.586-.565q0-4.059,0-8.117h0c0-2.735-.007-5.47.008-8.2M79.7,110.169c0,.342-.1.449-.448.448q-5.5-.016-10.993,0c-.376,0-.463-.14-.462-.485q.014-5.453,0-10.905c0-.385.121-.5.5-.494,1.832.017,3.664.007,5.5.007,1.8,0,3.606.01,5.409-.007.377,0,.5.1.5.489q-.02,5.474,0,10.948'
          transform='translate(-27.641 -40.783)'
          fill={palette.common.white}
        />
      </g>
    </SvgIcon>
  );
};
