import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareActivityItemUpdateFormPropsEvent } from "pages/Templates/types";
import {
  GuidedCareTemplateActivityItemUpdateForm,
  GuidedCareTemplateActivityItemUpdateFormRef,
} from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateForm";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../../forms/TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

type GuidedCareTemplateActivityItemUpdateModalProps = BaseDialogProps & {
  activityItem?: IGuidedCareTemplateActivityItemUpdateFormValues;
  onChange: (values: IGuidedCareActivityItemUpdateFormPropsEvent) => void;
};

export const GuidedCareTemplateActivityItemUpdateModal: FC<GuidedCareTemplateActivityItemUpdateModalProps> = props => {
  const { activityItem, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");
  const activityItemUpdateFormRef = useRef<GuidedCareTemplateActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={t("Update Activity Item")}
      confirmButton={{ label: t("Update"), onClick: onUpdateClick }}
      cancelButton={{ label: t("Cancel") }}
    >
      <GuidedCareTemplateActivityItemUpdateForm activityItem={activityItem} ref={activityItemUpdateFormRef} onChange={onChange} />
    </BaseDialog>
  );
};
