import { FC } from "react";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast, useModal } from "@toolkit/ui";
import { ProviderGuidedCareProgramTeamMember } from "@health/queries/types";
import {
  GuidedCareProgramTeamListDocument,
  GuidedCareProgramTeamManageMutation,
  useGuidedCareProgramTeamManageMutation,
} from "pages/Programs/gql";
import { IGuidedCareProgramTeamMemberListModalEvent } from "pages/Programs/types";
import { GuidedCareProgramTeamMemberListModal } from "../../modals/ProgramTeamMemberList/GuidedCareProgramTeamMemberListModal";

type GuidedCareProgramTeamMemberListViewProps = {
  id: string;
  teamMembers: ProviderGuidedCareProgramTeamMember[];
};

export const GuidedCareProgramTeamMemberListView: FC<GuidedCareProgramTeamMemberListViewProps> = props => {
  const { id, teamMembers } = props;

  const { t } = useTranslation("gcadmin");
  const { open, handleClose, handleOpen } = useModal();
  const { succeeded, failed } = useAddToast();

  const [fetchGuidedCareProgramTeamManageMutation, { loading }] = useGuidedCareProgramTeamManageMutation({
    onCompleted: (data: GuidedCareProgramTeamManageMutation) => {
      const mutationErrors = data?.ProviderGuidedCareHealthProgramTeamPayerResponse?.providerGuidedCareHealthProgramTeamErrors;

      if (!mutationErrors?.length) {
        succeeded(t("Program Care Team updated successfully"));
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
      handleClose();
    },
    onError: () => {
      failed(t("Program Care Team update failed"));
      handleClose();
    },
    refetchQueries: [GuidedCareProgramTeamListDocument],
  });

  const onGuidedCareProgramTeamMemberListModalChange = (event: IGuidedCareProgramTeamMemberListModalEvent) => {
    if (event.type === "CHANGE") {
      fetchGuidedCareProgramTeamManageMutation({
        variables: {
          input: {
            teamId: id,
            isResponseApprove: event.payload.isApprove,
          },
        },
      });
    }
  };

  return (
    <>
      <Button variant={"contained"} color={"warning"} onClick={handleOpen}>
        {t("View")}
      </Button>

      {open && (
        <GuidedCareProgramTeamMemberListModal
          open={open}
          teamMembers={teamMembers!}
          isLoading={loading}
          onClose={handleClose}
          onChange={onGuidedCareProgramTeamMemberListModalChange}
        />
      )}
    </>
  );
};
