import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareProgramMemberUpsertFormValues } from "pages/Programs/forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";
import { IGuidedCareProgramActivityUpsertFormEvent } from "pages/Programs/types";
import {
  GuidedCareProgramActivityUpsertForm,
  GuidedCareProgramActivityUpsertFormRef,
} from "../../forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertForm";
import { IGuidedCareProgramActivityUpsertFormValues } from "../../forms/ProgramActivityUpsert/GuidedCareProgramActivityUpsertFormSchema";

type GuidedCareProgramActivityUpsertModalProps = BaseDialogProps & {
  teamMembers: IGuidedCareProgramMemberUpsertFormValues[];
  activity?: IGuidedCareProgramActivityUpsertFormValues;
  onChange: (event: IGuidedCareProgramActivityUpsertFormEvent) => void;
};

export const GuidedCareProgramActivityUpsertModal: FC<GuidedCareProgramActivityUpsertModalProps> = props => {
  const { teamMembers, activity, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");
  const programActivityUpsertFormRef = useRef<GuidedCareProgramActivityUpsertFormRef>(null);

  const onButtonClick = () => {
    programActivityUpsertFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={activity ? t("Update Activity") : t("Create Activity")}
      confirmButton={{ label: activity ? t("Update") : t("Add"), onClick: onButtonClick }}
      cancelButton={activity ? { label: t("Cancel") } : undefined}
    >
      <GuidedCareProgramActivityUpsertForm
        teamMembers={teamMembers}
        activity={activity}
        onChange={onChange}
        ref={programActivityUpsertFormRef}
      />
    </BaseDialog>
  );
};
