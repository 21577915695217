/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageGetQueryVariables = Types.Exact<{
  medicalMessageId: Types.Scalars['ID'];
}>;


export type MedicalMessageGetQuery = { __typename?: 'Query', medicalMessage?: { __typename?: 'MedicalMessage', id: string, code?: string | null, name?: string | null, headerImage?: string | null, medicalMessageType?: Types.MedicalMessageType | null, medicalMessageCategory?: Types.MedicalMessageCategory | null, healthCondition?: { __typename?: 'HealthCondition', id?: string | null, code?: string | null, display?: string | null, arabicDisplay?: string | null } | null } | null };


export const MedicalMessageGetDocument = gql`
    query MedicalMessageGet($medicalMessageId: ID!) {
  medicalMessage(id: $medicalMessageId) {
    id
    code
    name
    headerImage
    medicalMessageType
    medicalMessageCategory
    healthCondition {
      id
      code
      display
      arabicDisplay
    }
  }
}
    `;

/**
 * __useMedicalMessageGetQuery__
 *
 * To run a query within a React component, call `useMedicalMessageGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageGetQuery({
 *   variables: {
 *      medicalMessageId: // value for 'medicalMessageId'
 *   },
 * });
 */
export function useMedicalMessageGetQuery(baseOptions: Apollo.QueryHookOptions<MedicalMessageGetQuery, MedicalMessageGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageGetQuery, MedicalMessageGetQueryVariables>(MedicalMessageGetDocument, options);
      }
export function useMedicalMessageGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageGetQuery, MedicalMessageGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageGetQuery, MedicalMessageGetQueryVariables>(MedicalMessageGetDocument, options);
        }
export type MedicalMessageGetQueryHookResult = ReturnType<typeof useMedicalMessageGetQuery>;
export type MedicalMessageGetLazyQueryHookResult = ReturnType<typeof useMedicalMessageGetLazyQuery>;
export type MedicalMessageGetQueryResult = Apollo.QueryResult<MedicalMessageGetQuery, MedicalMessageGetQueryVariables>;