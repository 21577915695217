import { i18n } from "@toolkit/i18n";
import { healthParametersPaths } from "./healthParametersPaths";
import { adminTranslationNamespace } from "utils/translation";

export const healthParametersTitleBreadcrumb = () => {
  return i18n.t("Health Parameters", adminTranslationNamespace);
};

export const healthParametersListBreadcrumb = () => {
  return {
    id: "health-parameters-list",
    name: i18n.t("Health Parameters", adminTranslationNamespace),
    route: healthParametersPaths.listPath.fullPath,
  };
};

export const healthParametersNewBreadcrumb = () => {
  return {
    id: "health-parameters-new",
    name: i18n.t("New Parameter", adminTranslationNamespace),
  };
};

export const healthParametersFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "health-parameters-field",
    name: fieldName,
  };
};
