import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { GuidedCareProgramActivityServicesForm } from "../../forms/ProgramActivityServicesForm/GuidedCareProgramActivityServicesForm";

type GuidedCareProgramActivityServicesModalProps = BaseDialogProps & {
  activityIndex: number;
  isReadOnly?: boolean;
};

export const GuidedCareProgramActivityServicesModal: FC<GuidedCareProgramActivityServicesModalProps> = props => {
  const { activityIndex, isReadOnly, ...rest } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <BaseDialog {...rest} title={t("Activity Services")} cancelButton={!isReadOnly ? { label: t("Close") } : undefined}>
      <GuidedCareProgramActivityServicesForm isReadOnly={isReadOnly} activityIndex={activityIndex} />
    </BaseDialog>
  );
};
