import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { TeamMemberPosition } from "@health/queries/types";
import { guidedCareTemplatesPaths } from "pages/Templates/constants";
import {
  GuidedCareTemplateGetDocument,
  GuidedCareTemplateUpdateMutation,
  useGuidedCareTemplateGetQuery,
  useGuidedCareTemplateUpdateMutation,
} from "pages/Templates/gql";
import { useGuidedCareTemplatesBreadcrumbs } from "pages/Templates/hooks";
import { compareTemplateActivities, convertTemplateFormValuesToBackEndValues } from "pages/Templates/others";
import { IGuidedCareTemplateUpdateContainerParams, IGuidedCareTemplateUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplateUpsertForm } from "../../forms/TemplateUpsert/GuidedCareTemplateUpsertForm";

export const GuidedCareTemplateUpdateContainer = () => {
  const { t } = useTranslation("gcadmin");
  const { succeeded, failed } = useAddToast();
  const navigate = useNavigate();

  const { setGuidedCareTemplateUpdateContainerBreadcrumb } = useGuidedCareTemplatesBreadcrumbs();

  const { templateId } = useParams<IGuidedCareTemplateUpdateContainerParams>();

  const { data, loading } = useGuidedCareTemplateGetQuery({
    variables: { id: templateId! },
    errorPolicy: "ignore",
    skip: !templateId,
  });

  const template = data?.healthProgramTemplate;

  const [fetchTemplateUpdateMutation, { loading: isSubmitting }] = useGuidedCareTemplateUpdateMutation({
    onCompleted: (mutationData: GuidedCareTemplateUpdateMutation) => {
      const mutationErrors = mutationData?.healthProgramTemplateUpdate?.healthProgramTemplateErrors;
      if (!mutationErrors?.length) {
        succeeded(t("Template updated successfully"));
        navigate(guidedCareTemplatesPaths.list.fullPath);
      } else {
        failed(t(formatMessageErrors(mutationErrors)));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(t(formatGraphQLError(graphQLErrors)));
    },
    refetchQueries: [GuidedCareTemplateGetDocument],
  });

  const onTemplateUpsertFormChange = (event: IGuidedCareTemplateUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const formValues = event.payload.values;

      const teamLeader = formValues?.teamMembers?.find(item => item?.position?.value === TeamMemberPosition.TeamLeader);

      if (!formValues?.activities?.length) {
        failed(t("Template must have at least one activity"));
        return;
      }

      if (!teamLeader?.specialization) {
        failed(t("Team Leader must have a specialization"));
        return;
      }

      const values = convertTemplateFormValuesToBackEndValues(formValues);
      const templateActivities = compareTemplateActivities(template, values);

      if (!templateId) return;

      fetchTemplateUpdateMutation({
        variables: {
          input: {
            ...values,
            id: templateId,
            code: template?.code,
            templateActivities,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (template?.name) {
      setGuidedCareTemplateUpdateContainerBreadcrumb(template.name);
    }
  }, [template?.name, setGuidedCareTemplateUpdateContainerBreadcrumb]);

  return (
    <GuidedCareTemplateUpsertForm
      submitButtonLabel={t("Update")}
      isLoading={loading}
      isButtonDisabled={isSubmitting}
      template={template!}
      onChange={onTemplateUpsertFormChange}
    />
  );
};
