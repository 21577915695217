import { zodSchema } from "@health/domains";
import { z } from "zod";

export const MedicalMessageVariantUpsertFormSchema = z.object({
  presentedTitleArabic: z.string().min(1).max(255),
  presentedTitleEnglish: z.string().min(1).max(255),
  bodyArabic: z.string().min(1).max(255),
  bodyEnglish: z.string().min(1).max(255),
  contentURLArabic: z.string().max(255).optional(),
  contentURLEnglish: z.string().max(255).optional(),
  buttonTextArabic: z.string().max(255).optional(),
  buttonTextEnglish: z.string().max(255).optional(),
  sessionArabic: zodSchema.strapiSessionsAutocompleteOptional,
  sessionEnglish: zodSchema.strapiSessionsAutocompleteOptional,
  videoArabic: zodSchema.sadaVideoAutocompleteOptional,
  videoEnglish: zodSchema.sadaVideoAutocompleteOptional,
});

export type IMedicalMessageVariantUpsertFormValues = z.infer<typeof MedicalMessageVariantUpsertFormSchema>;

export const medicalMessageVariantUpsertFormDefaultValues: Partial<IMedicalMessageVariantUpsertFormValues> = {
  presentedTitleArabic: undefined,
  presentedTitleEnglish: undefined,
  bodyArabic: undefined,
  bodyEnglish: undefined,
  contentURLArabic: undefined,
  contentURLEnglish: undefined,
  buttonTextArabic: undefined,
  buttonTextEnglish: undefined,
};
