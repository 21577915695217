import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, useModal } from "@toolkit/ui";
import { GuidedCareProgramTemplateActivityItemListModal } from "../../modals/ProgramTemplateActivityItemList/GuidedCareProgramTemplateActivityItemListModal";

type GuidedCareProgramTemplateActivityItemListViewProps = {
  templateActivityId: string;
};

export const GuidedCareProgramTemplateActivityItemListView: FC<GuidedCareProgramTemplateActivityItemListViewProps> = props => {
  const { templateActivityId } = props;

  const { t } = useTranslation("gcadmin");

  const { open, handleOpen, handleClose } = useModal();

  return (
    <>
      <Button onClick={handleOpen}> {t("View")} </Button>

      {open && (
        <GuidedCareProgramTemplateActivityItemListModal open={open} onClose={handleClose} templateActivityId={templateActivityId!} />
      )}
    </>
  );
};
