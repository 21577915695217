import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useCustomFormContext } from "@toolkit/core";
import { Grid, ScrollableTable } from "@toolkit/ui";
import { IGuidedCareProgramActivityServiceListItem, IGuidedCareProgramActivityServiceUpsertFormEvent } from "pages/Programs/types";
import { GuidedCareProgramActivityServiceUpsertForm } from "../ProgramActivityServiceUpsert/GuidedCareProgramActivityServiceUpsertForm";
import { useGuidedCareProgramActivityServicesColumns } from "../ProgramActivityServicesForm/useGuidedCareProgramActivityServicesColumns";
import { IGuidedCareProgramUpsertFormValues } from "../ProgramUpsert/GuidedCareProgramUpsertFormSchema";

type GuidedCareActivityServicesFormProps = {
  isReadOnly?: boolean;
  activityIndex: number;
};

export const GuidedCareProgramActivityServicesForm: FC<GuidedCareActivityServicesFormProps> = props => {
  const { isReadOnly = false, activityIndex } = props;

  const form = useCustomFormContext<IGuidedCareProgramUpsertFormValues>();
  const { control } = form;

  const { fields, append, remove } = useFieldArray<IGuidedCareProgramUpsertFormValues, `activities.${number}.activityServices`>({
    control,
    name: `activities.${activityIndex}.activityServices`,
  });

  const onActivityServiceUpsertFormChange = (event: IGuidedCareProgramActivityServiceUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      append(event.payload.values);
    }
  };

  const onDeleteRowClick = (item: IGuidedCareProgramActivityServiceListItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);

    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      {!isReadOnly && (
        <Grid item xs={12}>
          <GuidedCareProgramActivityServiceUpsertForm onChange={onActivityServiceUpsertFormChange} />
        </Grid>
      )}

      <Grid item xs={12}>
        <ScrollableTable
          data={fields}
          columns={useGuidedCareProgramActivityServicesColumns()}
          isDeleteVisible={!isReadOnly}
          isRowDeletable={!isReadOnly}
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
