import { makeStyles } from "@toolkit/ui";

export const useGuidedCareTemplateActivityItemsFormStyle = makeStyles()(theme => ({
  title: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
  subtitle: {
    fontSize: 13,
    fontFamily: theme.mixins.fonts.fontFamily.medium,
  },
}));
