import { i18n } from "@toolkit/i18n";
import { healthConditionsPaths } from "./healthConditionsPaths";
import { adminTranslationNamespace } from "utils/translation";

export const healthConditionsTitleBreadcrumb = () => {
  return i18n.t("Health Conditions", adminTranslationNamespace);
};

export const healthConditionsListBreadcrumb = () => {
  return {
    id: "health-conditions-list",
    name: i18n.t("Health Conditions", adminTranslationNamespace),
    route: healthConditionsPaths.listPath.fullPath,
  };
};

export const healthConditionsNewBreadcrumb = () => {
  return {
    id: "health-condition-new",
    name: i18n.t("New condition", adminTranslationNamespace),
  };
};

export const healthConditionsEditBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "health-conditions-edit",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};
export const healthConditionsFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "health-conditions-fields",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};

export const healthConditionsParametersBreadcrumb = () => {
  return {
    id: "health-conditions-parameters",
    name: i18n.t("Parameters", adminTranslationNamespace),
  };
};

export const healthConditionsCloneBreadcrumb = () => {
  return {
    id: "health-conditions-clone",
    name: i18n.t("Clone Condition", adminTranslationNamespace),
  };
};
export const healthConditionsDiagnosisBreadcrumb = () => {
  return {
    id: "health-conditions-diagnosis",
    name: i18n.t("Diagnosis", adminTranslationNamespace),
  };
};
