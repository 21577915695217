import { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { Button, Grid, useModal } from "@toolkit/ui";
import { GuidedCareActivityType } from "@health/queries/types";
import { GuidedCareTemplateActivityServicesModal } from "../../modals/TemplateActivityServices/GuidedCareTemplateActivityServicesModal";

type GuidedCareTemplateActivityServicesManageProps = {
  activityType: GuidedCareActivityType;
  activityIndex: number;
};

export const GuidedCareTemplateActivityServicesManage: FC<GuidedCareTemplateActivityServicesManageProps> = props => {
  const { activityType, activityIndex } = props;

  const { t } = useTranslation("gcadmin");
  const { open, handleOpen, handleClose } = useModal();

  return (
    <Grid item xs={12}>
      <Button
        onClick={handleOpen}
        variant={"contained"}
        disabled={activityType !== GuidedCareActivityType.Lab && activityType !== GuidedCareActivityType.Rad}
      >
        {t("Manage")}
      </Button>

      {open && <GuidedCareTemplateActivityServicesModal open={open} activityIndex={activityIndex} onClose={handleClose} />}
    </Grid>
  );
};
