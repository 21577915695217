import { useCustomFormContext } from "@toolkit/core";
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormHelperText } from "@mui/material";
import { get } from "lodash";
import React, { ChangeEvent, FC, ReactElement } from "react";
import { Controller } from "react-hook-form";

type FormCheckboxProps = CheckboxProps & {
  name: string;
  label: string | ReactElement;
};

export const FormCheckbox: FC<FormCheckboxProps> = props => {
  const { name, label, disabled, ...rest } = props;

  const {
    control,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error: string = get(errors, name + ".message", "");

  return (
    <Box>
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
                field.onChange(event.target.checked);
              };

              return (
                <Checkbox {...rest} {...field} checked={field?.value} disabled={isFormDisabled || disabled} onChange={handleOnChange} />
              );
            }}
          />
        }
      />

      {!!error && <FormHelperText error> {error} </FormHelperText>}
    </Box>
  );
};
