import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareProgramMemberUpsertFormEvent } from "pages/Programs/types";
import {
  GuidedCareProgramMemberUpsertForm,
  GuidedCareProgramMemberUpsertFormRef,
} from "../../forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertForm";
import { IGuidedCareProgramMemberUpsertFormValues } from "../../forms/ProgramMemberUpsert/GuidedCareProgramMemberUpsertFormSchema";

type GuidedCareProgramMemberUpsertModalProps = BaseDialogProps & {
  member?: IGuidedCareProgramMemberUpsertFormValues;
  onChange: (values: IGuidedCareProgramMemberUpsertFormEvent) => void;
};

export const GuidedCareProgramMemberUpsertModal: FC<GuidedCareProgramMemberUpsertModalProps> = props => {
  const { member, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");
  const programMemberUpsertFormRef = useRef<GuidedCareProgramMemberUpsertFormRef>(null);

  const onButtonClick = () => {
    programMemberUpsertFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={member ? t("Update Team Member") : t("Add Team Member")}
      confirmButton={{ label: member ? t("Update") : t("Add"), onClick: onButtonClick }}
      cancelButton={member ? { label: t("Cancel") } : undefined}
    >
      <GuidedCareProgramMemberUpsertForm member={member} ref={programMemberUpsertFormRef} onChange={onChange} />
    </BaseDialog>
  );
};
