import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { ScrollableTableColumnProps } from "@toolkit/ui";
import { IGuidedCareTemplateActivityItemUpdateFormValues } from "../TemplateActivityItemUpdate/GuidedCareTemplateActivityItemUpdateFormSchema";

export const useGuidedCareTemplateActivityItemsColumns =
  (): ScrollableTableColumnProps<IGuidedCareTemplateActivityItemUpdateFormValues>[] => {
    const { t } = useTranslation("gcadmin");

    return useMemo(() => {
      return [
        {
          key: "offsetInDays",
          header: t("Offset (Days)"),
          accessor: "offsetInDays",
        },
        {
          key: "beforeActivity",
          header: t("Before Activity"),
          accessor: ({ beforeActivity }) => beforeActivity?.label,
        },
        {
          key: "beforeActivityOffset",
          header: t("Before Activity Offset (Days)"),
          accessor: "beforeActivityOffset",
        },
        {
          key: "afterActivity",
          header: t("After Activity"),
          accessor: ({ afterActivity }) => afterActivity?.label,
        },
        {
          key: "afterActivityOffset",
          header: t("After Activity Offset (Days)"),
          accessor: "afterActivityOffset",
        },
      ];
    }, [t]);
  };
