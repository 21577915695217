import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { ProviderGuidedCareProgramTeamMember } from "@health/queries/types";
import { IGuidedCareProgramTeamMemberListModalEvent } from "pages/Programs/types";
import { GuidedCareProgramTeamMemberList } from "../../components/ProgramTeamMemberList/GuidedCareProgramTeamMemberList";

type GuidedCareProgramTeamMemberListModalProps = BaseDialogProps & {
  teamMembers: ProviderGuidedCareProgramTeamMember[];
  isLoading?: boolean;
  onChange: (event: IGuidedCareProgramTeamMemberListModalEvent) => void;
};

export const GuidedCareProgramTeamMemberListModal: FC<GuidedCareProgramTeamMemberListModalProps> = props => {
  const { teamMembers, onChange, isLoading, ...rest } = props;

  const { t } = useTranslation("gcadmin");

  const onApproveClick = () => {
    onChange({
      type: "CHANGE",
      payload: {
        isApprove: true,
      },
    });
  };

  const onRejectClick = () => {
    onChange({
      type: "CHANGE",
      payload: {
        isApprove: false,
      },
    });
  };

  return (
    <BaseDialog
      {...rest}
      title={t("Program Care Team")}
      confirmButton={{ label: t("Approve"), disabled: isLoading, onClick: onApproveClick }}
      cancelButton={{ label: t("Reject"), disabled: isLoading, onClick: onRejectClick }}
    >
      <GuidedCareProgramTeamMemberList teamMembers={teamMembers} />
    </BaseDialog>
  );
};
