import { HealthParameter, Maybe, TemplateFieldType } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, ShowButton, TruncateTypography, Typography, useTheme } from "@toolkit/ui";
import { defaultTo } from "lodash";
import { FC } from "react";
import { useToggle } from "shared/hooks";

export const AllowedValues: FC<Partial<HealthParameter>> = ({
  allowedValues,
  minNormalRangeValue,
  maxNormalRangeValue,
  defaultValue,
  type,
}) => {
  const { t } = useTranslation("gcadmin");
  const { open, handleToggle } = useToggle();
  const theme = useTheme();
  return (
    <CustomDialog
      maxWidth='sm'
      scrollType='body'
      title={t("Parameter Values")}
      open={open}
      hasClose
      button={
        <ShowButton
          variant='text'
          onClick={handleToggle}
          disabled={!(type == TemplateFieldType.Number || type === TemplateFieldType.String)}
        />
      }
      onCloseModal={handleToggle}
      footerComponent={
        <Button variant='outlined' onClick={handleToggle} sx={{ width: 30 }}>
          {t("Done")}
        </Button>
      }
      DialogContentProps={{
        sx: { minHeight: "unset" },
      }}
    >
      {type == TemplateFieldType.Number ? (
        <Box marginX={"10px"}>
          <Section
            label={t("Min Normal Range Value")}
            value={defaultTo(minNormalRangeValue + "", "-")}
            fontSize={theme.mixins.fonts.fontSize.sm}
          />
          <Section
            label={t("Max Normal Range Value")}
            value={defaultTo(maxNormalRangeValue + "", "-")}
            fontSize={theme.mixins.fonts.fontSize.sm}
          />
        </Box>
      ) : (
        <Box marginX={"10px"}>
          <Typography fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
            {t("Default Value")}
            {": "}
            <TruncateTypography text={defaultValue || ""} />
          </Typography>
          <Typography marginTop={"5px"} fontWeight='bold' fontSize={theme.mixins.fonts.fontSize.sm}>
            {t("Allowed Values")}
            {": "}
          </Typography>
          {allowedValues?.length ? allowedValues.map((e, index) => <TruncateTypography key={index} text={e || ""} />) : "-"}
        </Box>
      )}
    </CustomDialog>
  );
};

export const Section: FC<{ label: string; value?: Maybe<string>; fontSize: number }> = ({ label, value = "", fontSize }) => (
  <Box marginTop='10px' marginBottom='10px'>
    <Typography fontWeight='bold' fontSize={fontSize}>
      {label}:{" "}
      <Typography display={"inline"} fontSize={fontSize}>
        {value}
      </Typography>
    </Typography>
  </Box>
);
