import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { GuidedCareProgramTemplateActivityItemList } from "../../components/ProgramTemplateActivityItemList/GuidedCareProgramTemplateActivityItemList";

type GuidedCareProgramTemplateActivityItemListModalProps = BaseDialogProps & {
  templateActivityId: string;
};

export const GuidedCareProgramTemplateActivityItemListModal: FC<GuidedCareProgramTemplateActivityItemListModalProps> = props => {
  const { templateActivityId, ...rest } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <BaseDialog {...rest} title={t("Activity Items")}>
      <GuidedCareProgramTemplateActivityItemList templateActivityId={templateActivityId} />
    </BaseDialog>
  );
};
