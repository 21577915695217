import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { GuidedCareTemplateActivityServicesForm } from "../../forms/TemplateActivityServicesForm/GuidedCareTemplateActivityServicesForm";

type GuidedCareTemplateActivityServicesModalProps = BaseDialogProps & {
  activityIndex: number;
};

export const GuidedCareTemplateActivityServicesModal: FC<GuidedCareTemplateActivityServicesModalProps> = props => {
  const { activityIndex, ...rest } = props;

  const { t } = useTranslation("gcadmin");

  return (
    <BaseDialog {...rest} title={t("Activity Services")} cancelButton={{ label: t("Close") }}>
      <GuidedCareTemplateActivityServicesForm activityIndex={activityIndex} />
    </BaseDialog>
  );
};
