import { adminManagedListsRoutes } from "pages/AdminManagedLists/route";
import { guidedCareRoutes } from "pages/GuidedCare/constants";
import { HealthConditionsRoutes } from "pages/HealthConditions/route";
import { HealthParametersRoutes } from "pages/HealthParameters";
import { HealthSymptomsRoutes } from "pages/HealthSymptoms/route";
import { HealthTemplates } from "pages/HealthTemplate/route";
import { medicalFormsRoutes } from "pages/MedicalForms";
import { medicalMessagesRoutes } from "pages/MedicalMessages/constants";
import { decisionsRoutes } from "pages/Decisions/constants";

export const ROUTE_PATHS = {
  adminManagedListsRoutes,
  guidedCareRoutes,
  medicalFormsRoutes,
  medicalMessagesRoutes,
  HealthConditionsRoutes,
  HealthParametersRoutes,
  HealthTemplates,
  HealthSymptomsRoutes,
  decisionsRoutes,
};
