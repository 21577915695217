import { HealthSymptomSpecialization } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, Button, CustomDialog, ShowButton, Typography, useTheme } from "@toolkit/ui";
import { FC } from "react";
import { useHealthSymptomSpecializationsDelete } from "./useHealthSymptomSpecializationsDelete";

export const SpecializationsDialog: FC<{ specializations: HealthSymptomSpecialization[] | undefined | null }> = ({ specializations }) => {
  const { t, i18n } = useTranslation("gcadmin");

  const { open, handleClose, handleOpen } = useHealthSymptomSpecializationsDelete();
  const theme = useTheme();
  return (
    <CustomDialog
      hasClose
      title={t("Specializations")}
      open={open}
      onCloseModal={handleClose}
      button={<ShowButton onClick={handleOpen} />}
      footerComponent={<Button onClick={handleClose}>{t("Done")}</Button>}
      DialogContentProps={{
        sx: { minHeight: "unset" },
      }}
    >
      {specializations?.length ? (
        specializations?.map(specialization => (
          <Box key={specialization?.id} display='flex' justifyContent='space-between'>
            <div>
              {i18n?.language === "ar"
                ? specialization?.arabicDisplay || specialization?.display
                : specialization?.display || specialization?.code}
            </div>

            {/* <IconButton onClick={handleHealthSymptomSpecializationDelete(specialization?.id)}>
              {loading ? <CircularProgress /> : <CustomIcon icon='delete' />}
            </IconButton> */}
            {/* TODO - Fix this: 1. deleted element should loading 2. confirm the delete before do the action */}
          </Box>
        ))
      ) : (
        <Typography fontSize={theme.mixins.fonts.fontSize.md} fontFamily={theme.mixins.fonts.fontFamily.medium}>
          {t("There are no specializations")}
        </Typography>
      )}
    </CustomDialog>
  );
};
