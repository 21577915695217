import { useCallback, useMemo } from "react";
import { pickLocalizedText, useTranslation } from "@toolkit/i18n";
import { ScrollableTableColumnProps } from "@toolkit/ui";
import { guidedCareTeamMemberPositionOptionsMap } from "@health/enum-options";
import { GuidedCareProgramTeamMember, Maybe, ProviderGuidedCareProgramTeamMember } from "@health/queries/types";

export const useGuidedCareProgramTeamMemberListColumns = (): ScrollableTableColumnProps<ProviderGuidedCareProgramTeamMember>[] => {
  const { t } = useTranslation("gcadmin");

  const getPositionLabel = useCallback(
    (guidedCareProgramTeamMember?: Maybe<GuidedCareProgramTeamMember>, isBackup?: Maybe<boolean>) => {
      const position = guidedCareTeamMemberPositionOptionsMap[guidedCareProgramTeamMember?.position!]?.label;
      const backupLabel = isBackup ? t(`(Backup)`) : "";
      return `${position} ${backupLabel}`;
    },
    [t]
  );

  return useMemo(() => {
    return [
      {
        key: "position",
        header: t("Position"),
        accessor: ({ guidedCareProgramTeamMember, isBackup }) => getPositionLabel(guidedCareProgramTeamMember, isBackup),
      },
      {
        key: "user",
        header: t("User"),
        accessor: ({ user }) => `${user?.firstName} ${user?.lastName}`,
      },
      {
        key: "specialization",
        header: t("Specialization"),
        accessor: ({ guidedCareProgramTeamMember }) =>
          pickLocalizedText(guidedCareProgramTeamMember?.specializationDisplay!, guidedCareProgramTeamMember?.specializationArabicDisplay!),
      },
    ];
  }, [getPositionLabel, t]);
};
