import React, { FC } from "react";
import { useFieldArray } from "react-hook-form";
import { useCustomFormContext } from "@toolkit/core";
import { Grid, ScrollableTable } from "@toolkit/ui";
import { IGuidedCareActivityServiceListItem, IGuidedCareTemplateActivityServiceUpsertFormEvent } from "pages/Templates/types";
import { GuidedCareTemplateActivityServiceUpsertForm } from "../TemplateActivityServiceUpsert/GuidedCareTemplateActivityServiceUpsertForm";
import { useGuidedCareTemplateActivityServicesColumns } from "../TemplateActivityServicesForm/useGuidedCareTemplateActivityServicesColumns";
import { IGuidedCareTemplateUpsertFormValues } from "../TemplateUpsert/GuidedCareTemplateUpsertFormSchema";

type GuidedCareTemplateActivityServicesFormProps = {
  activityIndex: number;
};

export const GuidedCareTemplateActivityServicesForm: FC<GuidedCareTemplateActivityServicesFormProps> = props => {
  const { activityIndex } = props;

  const form = useCustomFormContext<IGuidedCareTemplateUpsertFormValues>();

  const { control } = form;

  const { fields, append, remove } = useFieldArray<IGuidedCareTemplateUpsertFormValues, `activities.${number}.activityServices`>({
    control,
    name: `activities.${activityIndex}.activityServices`,
  });

  const onActivityServiceUpsertFormChange = (event: IGuidedCareTemplateActivityServiceUpsertFormEvent) => {
    if (event.type === "CREATE") {
      append(event.payload.values);
    }
  };

  const onDeleteRowClick = (item: IGuidedCareActivityServiceListItem) => {
    const itemIndex = fields.findIndex(field => field.id === item.id);

    if (itemIndex > -1) {
      remove(itemIndex);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GuidedCareTemplateActivityServiceUpsertForm onChange={onActivityServiceUpsertFormChange} />
      </Grid>

      <Grid item xs={12}>
        <ScrollableTable
          data={fields}
          columns={useGuidedCareTemplateActivityServicesColumns()}
          isDeleteVisible
          isRowDeletable
          onDeleteRow={onDeleteRowClick}
        />
      </Grid>
    </Grid>
  );
};
