import { i18n } from "@toolkit/i18n";
import { healthSymptomsPaths } from "./healthSymptomsPaths";
import { adminTranslationNamespace } from "utils/translation";

export const healthSymptomsTitleBreadcrumb = () => {
  return i18n.t("Health Symptoms", adminTranslationNamespace);
};

export const healthSymptomsListBreadcrumb = () => {
  return {
    id: "health-symptoms-list",
    name: i18n.t("Health Symptoms", adminTranslationNamespace),
    route: healthSymptomsPaths.listPath.fullPath,
  };
};

export const healthSymptomsNewBreadcrumb = () => {
  return {
    id: "health-symptoms-new",
    name: i18n.t("New", adminTranslationNamespace),
  };
};

export const healthSymptomsFieldBreadcrumb = (fieldName: string) => {
  return {
    id: "health-symptoms-field",
    name: fieldName,
  };
};
