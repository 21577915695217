import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { ScrollableTableColumnProps } from "@toolkit/ui";
import { TemplateActivityEventItem } from "@health/queries/types";

export const useGuidedCareProgramTemplateActivityItemListColumns = (): ScrollableTableColumnProps<TemplateActivityEventItem>[] => {
  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "offsetInDays",
        header: t("Offset (Days)"),
        accessor: "offsetInDays",
      },
      {
        key: "beforeActivity",
        header: t("Before Activity"),
        accessor: ({ beforeCallMessage }) => beforeCallMessage?.name,
      },
      {
        key: "beforeActivityOffset",
        header: t("Before Activity Offset (Days)"),
        accessor: "beforeCallMessageOffsetDays",
      },
      {
        key: "afterActivity",
        header: t("After Activity"),
        accessor: ({ afterCallMessage }) => afterCallMessage?.name,
      },
      {
        key: "afterActivityOffset",
        header: t("After Activity Offset (Days)"),
        accessor: "afterCallMessageOffsetDays",
      },
    ];
  }, [t]);
};
