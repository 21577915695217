import { i18n } from "@toolkit/i18n";
import { riskFactorTemplatesPaths } from "./riskFactorTemplatesPaths";
import { adminTranslationNamespace } from "utils/translation";

export const riskFactorTemplatesTitleBreadcrumb = () => {
  return i18n.t("Risk Factor Templates", adminTranslationNamespace);
};

export const riskFactorTemplateListBreadcrumb = () => {
  return {
    id: "risk-factor-template-list",
    name: i18n.t("Risk Factor Templates", adminTranslationNamespace),
    route: riskFactorTemplatesPaths.listPath.fullPath,
  };
};

export const riskFactorTemplateNewBreadcrumb = () => {
  return {
    id: "risk-factor-template-new",
    name: i18n.t("New Template", adminTranslationNamespace),
  };
};

export const riskFactorTemplateFieldBreadcrumb = (fieldName: string, fieldRoute?: string) => {
  return {
    id: "guided-care-template-field",
    name: fieldName,
    route: fieldRoute || undefined,
  };
};

export const riskFactorTemplateParametersBreadcrumb = () => {
  return {
    id: "risk-factor-template-parameters",
    name: i18n.t("Parameters", adminTranslationNamespace),
  };
};

export const riskFactorTemplateCloneBreadcrumb = () => {
  return {
    id: "risk-factor-template-clone",
    name: i18n.t("Clone Template", adminTranslationNamespace),
  };
};
