import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import React, { FC } from "react";

export const CareGaps: FC<SvgIconProps> = props => {
  const { palette } = useTheme();
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='47.5' height='47.501' viewBox='0 0 47.5 47.501' {...props}>
      <path
        id='icons8-high-priority'
        d='M48.556,23.47,28.03,2.944a3.227,3.227,0,0,0-4.562,0L2.944,23.47a3.227,3.227,0,0,0,0,4.562L23.47,48.558a3.227,3.227,0,0,0,4.562,0L48.558,28.032A3.229,3.229,0,0,0,48.556,23.47ZM27.964,12.961,27.6,29.4H23.9l-.365-16.442ZM25.755,38.884c-1.513,0-2.417-.806-2.417-2.159a2.116,2.116,0,0,1,2.417-2.185,2.183,2.183,0,1,1,0,4.344Z'
        transform='translate(-2 -2)'
        fill={palette.common.white}
      />
    </SvgIcon>
  );
};
export default CareGaps;
