import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CodeSystemCode } from "@health/queries/types";
import { SystemCodeAutocomplete } from "@health/autocompletes";
import { useAddDiagnosesToHealthCondition } from "./useAddDiagnosesToHealthCondition";

export const AddFields: FC<{ open: boolean; onToggle: () => void }> = ({ open, onToggle: handleToggle }) => {
  const { t } = useTranslation("gcadmin");

  const { handleAddDiagnosesToHealthCondition } = useAddDiagnosesToHealthCondition(handleToggle);

  const methods = useForm({
    mode: "onChange",
  });

  const { handleSubmit } = methods;

  const onSubmit = input => {
    handleAddDiagnosesToHealthCondition(input?.diagnose?.value?.code);
  };

  return (
    <FormProvider {...methods}>
      <CustomDialog
        maxWidth='sm'
        scrollType='body'
        title={t("Diagnoses")}
        open={open}
        hasClose
        onCloseModal={handleToggle}
        footerComponent={
          <Button variant='outlined' type='submit' onClick={handleSubmit(onSubmit)}>
            {t("Add Diagnosis")}
          </Button>
        }
        button={
          <Button variant='outlined' onClick={handleToggle}>
            {t("Diagnosis")}
          </Button>
        }
        DialogContentProps={{
          sx: { minHeight: "unset" },
        }}
      >
        <SystemCodeAutocomplete
          name={"diagnose"}
          label={t("Diagnose")}
          placeholder={t("Diagnose")}
          filter={{ codeSystemCode: CodeSystemCode.Diagnosis }}
        />
      </CustomDialog>
    </FormProvider>
  );
};
