import { useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { ScrollableTableColumnProps } from "@toolkit/ui";
import { IGuidedCareProgramActivityItem } from "pages/Programs/types";
import { GuidedCareProgramActivityItemsManage } from "../../components/ProgramActivityItemsManage/GuidedCareProgramActivityItemsManage";
import { GuidedCareProgramActivityServicesManage } from "../../components/ProgramActivityServicesManage/GuidedCareProgramActivityServicesManage";
import { GuidedCareProgramTemplateActivityItemListView } from "../../components/ProgramTemplateActivityItemListView/GuidedCareProgramTemplateActivityItemListView";

type GuidedCareProgramActivitiesTableColumnsProps = {
  isFormDisabled?: boolean;
};

export const useGuidedCareProgramActivitiesColumns = (
  props: GuidedCareProgramActivitiesTableColumnsProps
): ScrollableTableColumnProps<IGuidedCareProgramActivityItem>[] => {
  const { isFormDisabled } = props;

  const { t } = useTranslation("gcadmin");

  return useMemo(() => {
    return [
      {
        key: "type",
        header: t("Type"),
        accessor: ({ templateActivityType }) => t(templateActivityType?.label!),
      },
      {
        key: "position",
        header: t("Position"),
        accessor: ({ teamMember }) => t(teamMember?.label!),
      },
      {
        key: "numberOfOccurrences",
        header: t("Occurrences"),
        accessor: ({ numberOfOccurrences, frequencyType }) => `${numberOfOccurrences} / ${t(frequencyType?.label)}`,
      },
      {
        key: "serviceCode",
        header: t("Services"),
        accessor: ({ templateActivityType, templateActivityId }, index) => {
          return (
            <GuidedCareProgramActivityServicesManage
              activityType={templateActivityType?.value}
              activityIndex={index}
              isReadOnly={isFormDisabled || !!templateActivityId}
            />
          );
        },
      },
      {
        key: "items",
        header: t("Items"),
        accessor: ({ activityId, templateActivityId }) => {
          return templateActivityId ? (
            <GuidedCareProgramTemplateActivityItemListView templateActivityId={templateActivityId} />
          ) : (
            <GuidedCareProgramActivityItemsManage activityId={activityId} isDisabled={isFormDisabled || !activityId} />
          );
        },
      },
    ];
  }, [isFormDisabled, t]);
};
