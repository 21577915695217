import { medicalMessageCategoryOptionsMap, medicalMessageTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, Typography } from "@toolkit/ui";
import { useMedicalMessageGetQuery } from "pages/MedicalMessages/gql";
import { FC } from "react";

type MedicalMessageInformationProps = {
  medicalMessageId: string;
};

export const MedicalMessageInformation: FC<MedicalMessageInformationProps> = props => {
  const { medicalMessageId } = props;

  const { t } = useTranslation("gcadmin");

  const { data, loading } = useMedicalMessageGetQuery({
    variables: { medicalMessageId: medicalMessageId! },
    skip: !medicalMessageId,
  });

  const medicalMessage = data?.medicalMessage;

  return (
    <FormCard title={t("Patient Engagement Messages Information")} loading={loading} doYouHaveData>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography fontSize={15}>
            {t("Name")}: {medicalMessage?.name}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={15}>
            {t("Code")}: {medicalMessage?.code}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={15}>
            {t("Type")}: {medicalMessageTypeOptionsMap[medicalMessage?.medicalMessageType!]?.label}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={15}>
            {t("Category")}: {medicalMessageCategoryOptionsMap[medicalMessage?.medicalMessageCategory!]?.label}
          </Typography>
        </Grid>
      </Grid>
    </FormCard>
  );
};
