import { NavigateFunction } from "react-router-dom";
import { RouteItem } from "shared/components";
import { TFunction } from "@toolkit/i18n";
import { hasPermission } from "@health/common";
import { PermissionEnum } from "@health/queries/types";
import {
  GuidedCareTemplateActivitiesItemListContainer,
  GuidedCareTemplateActivityItemListContainer,
  GuidedCareTemplateCreateContainer,
  GuidedCareTemplateListContainer,
  GuidedCareTemplateUpdateContainer,
} from "../containers";
import { guidedCareTemplatesPaths, guidedCareTemplatesRoute } from "./GuidedCareTemplatesPaths";

export const guidedCareTemplatesRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "guided-care-templates-routes",
    text: t("Templates", { ns: "gcadmin" }),
    route: guidedCareTemplatesRoute,
    isProhibited: !hasPermission(PermissionEnum.ManageProgramTemplates),
    subItems: [
      {
        id: "guided-care-template-list-route",
        route: guidedCareTemplatesPaths.list.route,
        fullPath: guidedCareTemplatesPaths.list.fullPath,
        element: <GuidedCareTemplateListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-create-route",
        route: guidedCareTemplatesPaths.create.route,
        fullPath: guidedCareTemplatesPaths.create.fullPath,
        hidden: true,
        element: <GuidedCareTemplateCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-update-route",
        route: guidedCareTemplatesPaths.update.route,
        fullPath: guidedCareTemplatesPaths.update.fullPath,
        hidden: true,
        element: <GuidedCareTemplateUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-activities-item-list-route",
        route: guidedCareTemplatesPaths.activitiesItemList.route,
        fullPath: guidedCareTemplatesPaths.activitiesItemList.fullPath,
        hidden: true,
        element: <GuidedCareTemplateActivitiesItemListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "guided-care-template-activity-item-list-route",
        route: guidedCareTemplatesPaths.activityItemList.route,
        fullPath: guidedCareTemplatesPaths.activityItemList.fullPath,
        hidden: true,
        element: <GuidedCareTemplateActivityItemListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
