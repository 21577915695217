import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareTemplateMemberUpsertFormEvent } from "pages/Templates/types";
import {
  GuidedCareTemplateMemberUpsertForm,
  GuidedCareTemplateMemberUpsertFormRef,
} from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertForm";
import { IGuidedCareTemplateMemberUpsertFormValues } from "../../forms/TemplateMemberUpsert/GuidedCareTemplateMemberUpsertFormSchema";

type GuidedCareTemplateMemberUpsertModalProps = BaseDialogProps & {
  member?: IGuidedCareTemplateMemberUpsertFormValues;
  onChange: (values: IGuidedCareTemplateMemberUpsertFormEvent) => void;
};

export const GuidedCareTemplateMemberUpsertModal: FC<GuidedCareTemplateMemberUpsertModalProps> = props => {
  const { member, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");
  const templateMemberUpsertFormRef = useRef<GuidedCareTemplateMemberUpsertFormRef>(null);

  const onButtonClick = () => {
    templateMemberUpsertFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={member ? t("Update Team Member") : t("Add Team Member")}
      confirmButton={{ label: member ? t("Update") : t("Add"), onClick: onButtonClick }}
      cancelButton={member ? { label: t("Cancel") } : undefined}
    >
      <GuidedCareTemplateMemberUpsertForm member={member} ref={templateMemberUpsertFormRef} onChange={onChange} />
    </BaseDialog>
  );
};
