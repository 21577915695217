/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalMessageVariantGetQueryVariables = Types.Exact<{
  medicalMessageVariantId: Types.Scalars['ID'];
}>;


export type MedicalMessageVariantGetQuery = { __typename?: 'Query', medicalMessageVariant?: { __typename?: 'MedicalMessageVariant', id: string, bodyArabic?: string | null, bodyEnglish?: string | null, buttonTextArabic?: string | null, buttonTextEnglish?: string | null, contentURLArabic?: string | null, contentURLEnglish?: string | null, presentedTitleArabic?: string | null, presentedTitleEnglish?: string | null, extraData?: string | null } | null };


export const MedicalMessageVariantGetDocument = gql`
    query MedicalMessageVariantGet($medicalMessageVariantId: ID!) {
  medicalMessageVariant(id: $medicalMessageVariantId) {
    id
    bodyArabic
    bodyEnglish
    buttonTextArabic
    buttonTextEnglish
    contentURLArabic
    contentURLEnglish
    presentedTitleArabic
    presentedTitleEnglish
    extraData
  }
}
    `;

/**
 * __useMedicalMessageVariantGetQuery__
 *
 * To run a query within a React component, call `useMedicalMessageVariantGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalMessageVariantGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalMessageVariantGetQuery({
 *   variables: {
 *      medicalMessageVariantId: // value for 'medicalMessageVariantId'
 *   },
 * });
 */
export function useMedicalMessageVariantGetQuery(baseOptions: Apollo.QueryHookOptions<MedicalMessageVariantGetQuery, MedicalMessageVariantGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalMessageVariantGetQuery, MedicalMessageVariantGetQueryVariables>(MedicalMessageVariantGetDocument, options);
      }
export function useMedicalMessageVariantGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalMessageVariantGetQuery, MedicalMessageVariantGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalMessageVariantGetQuery, MedicalMessageVariantGetQueryVariables>(MedicalMessageVariantGetDocument, options);
        }
export type MedicalMessageVariantGetQueryHookResult = ReturnType<typeof useMedicalMessageVariantGetQuery>;
export type MedicalMessageVariantGetLazyQueryHookResult = ReturnType<typeof useMedicalMessageVariantGetLazyQuery>;
export type MedicalMessageVariantGetQueryResult = Apollo.QueryResult<MedicalMessageVariantGetQuery, MedicalMessageVariantGetQueryVariables>;