/* eslint-disable max-lines */
import {
  GuidedCareActivityDurationPeriod,
  GuidedCareActivityItemFilter,
  guidedCareActivityDurationPeriodOptions,
  guidedCareActivityDurationPeriodOptionsMap,
  guidedCareActivityItemFilterOptions,
  guidedCareActivityItemFilterOptionsMap,
  guidedCareActivityTypeNonMedicalMessageOptions,
} from "@health/enum-options";
import { H_OrderDirection, TemplateActivity, TemplateActivityEventItemSortingField } from "@health/queries/types";
import { IEnumOption } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AnnualTimeLine, Box, CircularProgress, MonthlyTimeLine, Typography, useGridContext, useTheme } from "@toolkit/ui";
import moment from "moment";
import { useGuidedCareTemplateActivitiesItemListQuery } from "pages/Templates/gql";
import { IGuidedCareTemplateAllActivitiesItemsContainerParams } from "pages/Templates/types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { GuidedCareTemplateActivitiesItemsTimelineAnnual } from "../TemplateActivitiesItemsTimelineAnnual/GuidedCareTemplateActivitiesItemsTimelineAnnual";
import { GuidedCareTemplateActivitiesItemsTimelineMenu } from "../TemplateActivitiesItemsTimelineMenu/GuidedCareTemplateActivitiesItemsTimelineMenu";
import { GuidedCareTemplateActivitiesItemsTimelineQuarterly } from "../TemplateActivitiesItemsTimelineQuarterly/GuidedCareTemplateActivitiesItemsTimelineQuarterly";

export const GuidedCareTemplateActivitiesItemsTimeline = () => {
  const [selectItem, setSelectItem] = useState<TemplateActivity | undefined>(undefined);

  const [duration, setDuration] = useState<IEnumOption<GuidedCareActivityDurationPeriod>>(
    guidedCareActivityDurationPeriodOptionsMap[GuidedCareActivityDurationPeriod.Annual]
  );

  const [messagesFilter, setMessagesFilter] = useState<IEnumOption<GuidedCareActivityItemFilter>>(
    guidedCareActivityItemFilterOptionsMap[GuidedCareActivityItemFilter.ShowAllActivities]
  );

  const { t } = useTranslation("gcadmin");
  const { activeFiltersProps } = useGridContext();

  const { templateId } = useParams<IGuidedCareTemplateAllActivitiesItemsContainerParams>();

  const requestedFilters = activeFiltersProps?.requestedFilters;

  const { data, loading } = useGuidedCareTemplateActivitiesItemListQuery({
    variables: {
      first: 100,
      filter: {
        templateId: templateId!,
        ...requestedFilters,
        templateActivityType:
          messagesFilter?.value === GuidedCareActivityItemFilter.ShowAllActivities
            ? requestedFilters?.templateActivityType || []
            : guidedCareActivityTypeNonMedicalMessageOptions?.map(item => item?.value),
      },
      sortBy: {
        field: TemplateActivityEventItemSortingField.Offset,
        direction: H_OrderDirection.Asc,
      },
    },
  });

  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  const getMonthFromDays = (days: number) => {
    const date = moment().startOf("year").add(days, "days");
    return date.month() + 1;
  };

  const activityItems =
    data?.TemplateActivityEventItems?.edges?.map(item => ({
      month: getMonthFromDays(item?.node?.offsetInDays!),
      ...item?.node,
    })) || [];

  const getActivities = (month: number) => activityItems?.filter(item => item?.month === month);

  const timeline = months?.map(month => ({
    label: "Month" + " " + month,
    activities: getActivities(month),
    activitiesWeeks: {
      activitiesWeeks1: [],
      activitiesWeeks2: [],
      activitiesWeeks3: [],
      activitiesWeeks4: [],
    },
  }));

  const getWeekOfMonth = (day: number) => {
    const date = moment().dayOfYear(day);

    let weekOfMonth = Math.ceil((date.date() + moment(date).startOf("month").day()) / 7);

    if (weekOfMonth > 4) {
      weekOfMonth = 4;
    }
    return weekOfMonth;
  };

  timeline.forEach(item => {
    item.activities.forEach(activity => {
      const weekOfMonth = getWeekOfMonth(activity.offsetInDays!);
      item.activitiesWeeks[`activitiesWeeks${weekOfMonth}`].push(activity);
    });
  });

  const handleSelectItem = (item: TemplateActivity) => {
    selectItem?.id === item?.id ? setSelectItem(undefined) : setSelectItem(item);
  };

  const handleDuration = (item: IEnumOption<GuidedCareActivityDurationPeriod>) => {
    setDuration(item);
  };

  const handleMessagesFilter = (item: IEnumOption<GuidedCareActivityItemFilter>) => {
    setMessagesFilter(item);
  };
  const theme = useTheme();
  return (
    <Box>
      {loading ? (
        <Box display='flex' justifyContent='center'>
          <CircularProgress size={80} />
        </Box>
      ) : (
        <Box
          p={2}
          borderRadius={2}
          sx={{
            background: theme.palette.common.white,
          }}
        >
          <Box display='flex' justifyContent='space-between'>
            <Typography fontSize={"26px"} fontFamily={theme.mixins.fonts.fontFamily.medium} color={theme.palette.primary.main}>
              {t("Activity Items Timeline")}
            </Typography>

            <Box display='flex'>
              <GuidedCareTemplateActivitiesItemsTimelineMenu
                title={t("Duration")}
                items={guidedCareActivityDurationPeriodOptions}
                selected={duration!}
                onChange={handleDuration}
              />

              <GuidedCareTemplateActivitiesItemsTimelineMenu
                title={t("Messages")}
                items={guidedCareActivityItemFilterOptions}
                selected={messagesFilter!}
                onChange={handleMessagesFilter}
              />
            </Box>
          </Box>

          {duration?.value === GuidedCareActivityDurationPeriod.Annual ? (
            <AnnualTimeLine timeline={timeline}>
              <GuidedCareTemplateActivitiesItemsTimelineAnnual selectItem={selectItem!} onSelectItem={handleSelectItem} />
            </AnnualTimeLine>
          ) : (
            <MonthlyTimeLine timeline={timeline}>
              <GuidedCareTemplateActivitiesItemsTimelineQuarterly selectItem={selectItem!} onSelectItem={handleSelectItem} />
            </MonthlyTimeLine>
          )}
        </Box>
      )}
    </Box>
  );
};
