import React, { FC, useRef } from "react";
import { useTranslation } from "@toolkit/i18n";
import { BaseDialog, BaseDialogProps } from "@toolkit/ui";
import { IGuidedCareProgramActivityItemUpdateFormEvent } from "pages/Programs/types";
import {
  GuidedCareActivityItemUpdateFormRef,
  GuidedCareProgramActivityItemUpdateForm,
} from "../../forms/ProgramActivityItemUpdate/GuidedCareProgramActivityItemUpdateForm";
import { IGuidedCareProgramActivityItemUpdateFormValues } from "../../forms/ProgramActivityItemUpdate/GuidedCareProgramActivityItemUpdateFormSchema";

type GuidedCareProgramActivityItemUpdateModalProps = BaseDialogProps & {
  activityItem?: IGuidedCareProgramActivityItemUpdateFormValues;
  onChange: (values: IGuidedCareProgramActivityItemUpdateFormEvent) => void;
};

export const GuidedCareProgramActivityItemUpdateModal: FC<GuidedCareProgramActivityItemUpdateModalProps> = props => {
  const { activityItem, onChange, ...rest } = props;

  const { t } = useTranslation("gcadmin");

  const activityItemUpdateFormRef = useRef<GuidedCareActivityItemUpdateFormRef>(null);

  const onUpdateClick = () => {
    activityItemUpdateFormRef.current?.submit();
  };

  return (
    <BaseDialog
      {...rest}
      title={t("Update Activity Item")}
      confirmButton={{ label: t("Update"), onClick: onUpdateClick }}
      cancelButton={{ label: t("Cancel") }}
    >
      <GuidedCareProgramActivityItemUpdateForm activityItem={activityItem} ref={activityItemUpdateFormRef} onChange={onChange} />
    </BaseDialog>
  );
};
